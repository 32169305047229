<template>
  <div>
    <div
      v-if="paymentMethod && paymentMethod.name == 'WebPay'"
      >
      <div
        class="primary--text mb-3"
        v-if="!commercial_code"
        >
        Debes gestionar tu código de tienda con Transbank a través del botón que se encuentra disponible (envías los datos y un ejecutivo Transbank se contactará). Luego de este proceso debes ingresar el código obtenido.
      </div>
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        >
        <template>
          <v-text-field
            filled
            label="Código de comercio"
            hint="Commercial code"
            v-model="paymentMethod.preferences.commercial_code"
            autocomplete="new"
            ></v-text-field>

          <div
            class="text-right"
            >
            <transbank-signup></transbank-signup>
            <v-btn
              tile
              color="primary"
              class="ml-3 mt-3"
              @click="editPaymentMethod"
              >
              Guardar
            </v-btn>
          </div>
        </template>
      </v-form>

      <v-snackbar
        :timeout="3000"
        :color="alert.type"
        v-model="alert.active"
        >
        <v-icon
          class="mr-3"
          >mdi-{{ alert.type == 'error' ? 'close' : 'check' }}-circle</v-icon> {{ alert.message }}
      </v-snackbar>
    </div>

    <div
      v-if="paymentMethod.name == 'Payku'"
      >
      <payku-signup
        :paymentMethod="paymentMethod"
        @success="editPayku"
        @clone="clonePayku"
        ></payku-signup>
    </div>
  </div>
</template>

<script>
const TransbankSignup = () => import('@/components/admin/payment_methods/TransbankSignup')
const PaykuSignup = () => import('@/components/admin/payment_methods/Payku')

import { PaymentMethod } from '@/graphql/queries/payment_methods'
import { Edit, ClonePayku } from '@/graphql/mutations/payment_method'

export default {
  data () {
    return {
      valid: true,
      paymentMethod: null,
      showSecret: false,
      commercialCode: null,
      alert: {
        active: false,
        message: '',
        type: 'success'
      }
    }
  },

  props: {
    id: {
      required: true,
      type: Number | String
    }
  },

  mounted () {
    this.fetchPaymentMethod()
  },

  methods: {
    editPaymentMethod () {
      if (this.$refs.form) {
        this.valid = this.$refs.form.validate()
      }

      if (this.valid) {
        this.$apollo.mutate({
          mutation: Edit,
          variables: {
            input: {
              id: this.id,
              attributes: this.paymentMethod
            }
          }
        }).then( res => {
          this.fetchPaymentMethod()
          this.alert = {
            active: true,
            message: '¡Credenciales editadas con éxito!',
            type: 'success'
          }
        }).catch( error => {
          this.alert = {
            active: true,
            message: 'Credenciales incorrectas, por favor revíselas y vuelva a intentar.',
            type: 'error'
          }
        })
      }
    },

    editPayku (id) {
      this.paymentMethod.preferences.payku_id = id
      this.editPaymentMethod ()
    },

    clonePayku () {
      this.$apollo.mutate({
        mutation: ClonePayku,
        variables: {
          input: {
          }
        }
      }).then ( res => {
        this.fetchPaymentMethod()
        this.alert = {
          active: true,
          message: '¡Alta realizado con éxito!',
          type: 'success'
        }
      })
    },

    fetchPaymentMethod () {
      this.$apollo.query({
        query: PaymentMethod,
        variables: {
          id: this.id
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.paymentMethod = res.data.paymentMethod
        this.commercial_code = this.paymentMethod.preferences.commercial_code
        delete this.paymentMethod.__typename
      })
    }
  },

  components: { TransbankSignup, PaykuSignup }
}
</script>
