import gql from 'graphql-tag'

export const Edit = gql`
  mutation editPaymentMethod($input: EditPaymentMethodInput!) {
    editPaymentMethod(input: $input) {
      success
    }
  }
`

export const TransbankSignup = gql`
  mutation transbankSignup($input: TransbankSignupInput!) {
    transbankSignup(input: $input) {
      success
    }
  }
`

export const ClonePayku = gql`
  mutation clonePayku($input: ClonePaykuInput!) {
    clonePayku(input: $input) {
      success
    }
  }
`
